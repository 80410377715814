<template>
  <div>
    <div id="bp_error" style="display: none">Sorry, something went wrong</div>
    <div id="bp_div" />
  </div>
</template>

<script setup>
import { commonProps } from '../../utils/prismic';
import getHead from '~/utils/get-head';

const context = useNuxtApp();
const props = defineProps(commonProps);
const config = useRuntimeConfig();
const buyapowaEmbedUrl = config.public.buyapowaEmbedUrl;
const scripts = [
  {
    rel: 'preconnect',
    href: buyapowaEmbedUrl,
    crossorigin: true,
  },
  {
    async: true,
    src: buyapowaEmbedUrl,
  },
];

const meta = [
  {
    hid: 'buyapowa',
    property: 'og:url',
    content: 'https://bp.april.fr/iaf/april_raf1/og',
  },
];

useHead({
  ...getHead(context, scripts, null, {}, meta),
});

onMounted(() => {
  if (window.Buyapowa) {
    const buyapowaInstance = new window.Buyapowa(
      { url: 'https://bp.april.fr', market: 'april' },
      'bp_div',
      'bp_error'
    );

    buyapowaInstance.embedReferAFriend({
      campaign: 'april_raf1',
      locale: 'fr-fr',
    });
  }
});
</script>
